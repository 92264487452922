import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    gridContainerPortalAccess: {
        marginBottom: theme.spacing(6),

        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    gridMaxWidth: {
        maxWidth: '1440px',
    },
    collapseFormLogin: {
        width: '100%',
    },
    gridCollapseContainer: {
        padding: theme.spacing(5, 15, 10),
    },
}));
