import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    outerGridContainer: {
        maxWidth: '900px',
    },
    gridContainer: {
        padding: theme.spacing(6, 6, 4),
        maxWidth: '1440px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(5, 2, 4),
        },
    },
    gridContainerDescription: {
        marginTop: theme.spacing(4),
    },
    title: {
        color: theme.appColors.primaryBlue,
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        fontSize: '38px',
        fontWeight: '700',
        lineHeight: '48px',
        letterSpacing: '-0.01em',

        [theme.breakpoints.down('xs')]: {
            fontSize: '24px',
            lineHeight: '32px',
        },
    },
    underlineText: {
        height: 5,
        width: 57,
        backgroundColor: theme.appColors.secondaryColor,
        borderRadius: 32,
        marginTop: theme.spacing(2),
    },
    description: {
        maxWidth: 660,
        color: theme.appColors.darkGray,
        fontFamily: theme.typography.bancoDoBrasilTextos.fontFamily,
        fontSize: '16px',
        lineHeight: '18px',
    },
}));
